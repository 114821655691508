/* ---------------------------------------------------------------------- */
/*  Theme Default
/* ---------------------------------------------------------------------- */
@import '../modules/css3-mixins';
@import '../modules/variables';
$base-color: #f7f7f7;
$header-color: #333333;
$label-color: #f1552d;

a {
  color: $label-color;
}
.panel-white {
  color: #333333;
}

.available-panel {
  color: #ffffff;
  float: none;
  margin: auto;
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: 20px;

  button {
    border: none;
    color: $white;
    transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: $label-color !important;
}
.form-control.input-xs,
.select2-container.small,
.select2-container.small .select2-choice {
  height: 22px !important;
  max-height: 22px !important;
  font-size: 10px !important;
}
.select2-container.small .select2-choice {
  line-height: 14px;
}
body {
  background-color: $base-color;
  &.layout-boxed {
    background: url('../../images/bg_2.png') $base-color;
  }
  &.login {
    background-image: url('../images/test.jpg');
    background-color: $header-color;
  }
}
.main-container,
#pageslide-left,
#pageslide-right {
  background-color: $base-color !important;
}
.label-default,
.badge-default {
  background: $label-color !important;
}
.topbar {
  background: $header-color;
  @include background-gradient(
    lighten($header-color, 10%) 0px,
    $header-color 100%
  );
  > .container .navbar-brand,
  .logo {
    color: $white;
  }
  @media (max-width: 991px) {
    > .container .navbar-brand {
      left: 10px !important;
      img {
        margin-top: 10px;
      }
    }
  }
  .topbar-tools {
    > ul > li {
      > a {
        color: $white;
      }
      &.right-menu-toggle > a {
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: $header-color;
      }
    }
  }
  .nav li.dropdown.open > .dropdown-toggle,
  .nav li.dropdown.active > .dropdown-toggle,
  .nav li.dropdown.open.active > .dropdown-toggle {
    background: none;
    color: $white;
  }
  .nav > li:hover > a,
  .nav > li:active > a,
  .nav > li > a:focus {
    color: $white;
    background: none;
  }
}

ul.main-navigation-menu > li {
  a {
    border-bottom: none;
    border-top-color: darken($base-color, 5%);
    color: darken($base-color, 85%);
    i {
      color: desaturate(darken($base-color, 25%), 15%);
      font-weight: normal;
    }
  }
  &.active > a {
    background: desaturate(darken($base-color, 6%), 5%) !important;
    border-top: none !important;
    color: $black;
    i {
      color: $black;
    }
  }
}
ul.main-navigation-menu li > ul.sub-menu > li > a:hover {
  background: desaturate(darken($base-color, 6%), 5%);
}
ul.main-navigation-menu li > ul.sub-menu > li.open > a,
ul.main-navigation-menu li > ul.sub-menu > li.active > a,
ul.main-navigation-menu li > ul.sub-menu > li.open > a:hover,
ul.main-navigation-menu li > ul.sub-menu > li.active > a:hover {
  background: darken($base-color, 2%);
}
ul.main-navigation-menu > li.open > a,
ul.main-navigation-menu > li > a:hover,
ul.main-navigation-menu > li:hover > a {
  background-color: darken($base-color, 2%);
}
ul.main-navigation-menu li.divider {
  background-color: darken($base-color, 5%) !important;
}

ul.main-navigation-menu > li.open > a,
ul.main-navigation-menu > li > a:hover,
ul.main-navigation-menu > li:hover > a {
  background-color: darken($base-color, 2%);
}

ul.main-navigation-menu li.dropdown-header,
ul.main-navigation-menu .mega-menu-sub-title {
  color: desaturate(darken($base-color, 30%), 10%) !important;
}
ul.main-navigation-menu li.dropdown-header,
ul.main-navigation-menu .mega-menu-sub-title {
  color: desaturate(darken($base-color, 30%), 10%) !important;
}
ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu a {
  color: darken($base-color, 85%) !important;
  &:hover {
    background: none !important;
  }
}
ul.main-navigation-menu
  .mega-menu
  .mega-menu-content
  ul.mega-sub-menu
  li.active
  a,
ul.main-navigation-menu
  .mega-menu
  .mega-menu-content
  ul.mega-sub-menu
  li.active
  a:hover,
ul.main-navigation-menu
  .mega-menu
  .mega-menu-content
  ul.mega-sub-menu
  li.active
  a:focus {
  background-color: desaturate(darken($base-color, 6%), 5%) !important;
  text-decoration: none;
}
ul.main-navigation-menu
  .mega-menu
  .mega-menu-content
  ul.mega-sub-menu
  li
  a:hover {
  background-color: desaturate(darken($base-color, 6%), 5%) !important;
  text-decoration: none;
}
#horizontal-menu {
  .container {
    background: $base-color;
  }
  .navbar-collapse {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .navbar-nav {
      margin: 0px !important;
      > li {
        float: left !important;
        > a {
          color: darken($base-color, 45%);
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          &:hover {
            color: darken($base-color, 85%);
            background: darken($base-color, 5%);
          }
          &:active,
          &:focus {
            background: darken($base-color, 10%);
          }
          i {
            display: block;
            line-height: 0px;
            padding-top: 18px;
          }
        }
        &.active {
          > a {
            color: lighten($base-color, 85%);
            background: $label-color;
            &:after {
              border-top-color: $label-color;
            }
          }
        }
      }
    }
  }
}
.main-content {
  background: #dddddd;
  .panel-heading {
    .pull-right {
      position: absolute;
      right: 10px;
      top: 14px;
    }
  }
}
.go-top {
  color: darken($base-color, 45%);
}
.footer-inner {
  color: darken($base-color, 45%);
}
#pageslide-left {
  .navigation-toggler {
    .sb-toggle-left {
      background: url(../../images/dark_dots.png) no-repeat center center;
    }
  }
  .slide-tools {
    background-color: darken($base-color, 5%);
  }
  .btn {
    color: transparentize($black, 0.7);
    &:hover {
      color: $black;
      background-color: transparentize(
        darken(darken($base-color, 10%), 2%),
        0.6
      );
    }
  }
  .user-profile {
    border-color: darken($base-color, 5%);
    position: relative;
    h5 {
      color: desaturate(darken($base-color, 30%), 10%);
    }
    h4 {
      color: $black;
    }
  }
}
.closedbar {
  background: darken($base-color, 5%) url(../../images/dark_dots.png) no-repeat
    center 5px !important;
  &:hover {
    background-color: $base-color !important;
  }
}
#pageslide-right {
  .pageslide-title {
    color: transparentize($black, 0.6);
  }
  .notifications {
    a {
      color: transparentize($black, 0.6);
      background: darken($base-color, 3%);
      &:hover {
        background: darken($base-color, 5%);
      }
      .time {
        color: desaturate(darken($base-color, 30%), 30%);
      }
    }
  }
  .right-wrapper .nav-tabs li {
    a {
      background: $base-color;
      border-bottom-color: darken($base-color, 10%);
    }
    &.active a {
      border-right-color: darken($base-color, 10%);
      border-left-color: darken($base-color, 10%);
      color: transparentize($black, 0.1);
    }
  }
  .media-list .media {
    border-bottom: 1px solid darken($base-color, 10%);
    margin: 0;
    .media-heading {
      color: darken($blue, 20%) !important;
    }
    a:hover {
      background: darken($base-color, 10%);
    }
    .status-online {
      color: $green;
    }
    .media-body span {
      color: darken($base-color, 70%);
    }
    label {
      color: darken($base-color, 60%);
    }
  }
  .sidebar-title {
    color: transparentize($black, 0.6);
  }
  .sidebar-back {
    color: transparentize($black, 0.6);
    &:hover {
      color: transparentize($black, 0.4);
    }
  }
}
#style_selector_container {
  .box-title {
    color: transparentize($black, 0.2) !important;
  }
  .images {
    border-bottom: 1px solid darken($base-color, 5%);
    img.active {
      border: 2px solid darken($base-color, 65%);
    }
  }
}
footer {
  background-color: $base-color;
}
legend {
  text-decoration: none;
  /* font-weight: bold; */
  font-size: 11px;
  color: #858585;
  border-bottom: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #333333;
}

#station_form_longitude,
#station_form_latitude,
#ship_form_gps_coordinate_longitude,
#ship_form_gps_coordinate_latitude {
  display: flex;
  justify-content: flex-start;

  .form-group {
    display: flex;
    align-items: center;
    margin: 0 10px;

    label {
      width: 20%;
      margin: 0 10px;
    }
  }
}

.form-check {
  input[type='checkbox'] {
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    height: 2em;
    width: 4em;
    font-size: 1em;
    border-radius: 1.5em;
    background-color: rgb(244, 66, 66);
    border-color: transparent;
    background-clip: padding-box;
    color: #ddd;
    vertical-align: middle;
    transition: all 0.25s linear 0.25s;

    &.grey-button {
      background-color: #ccc;

      &:checked {
        background-color: #ccc;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 50%;
      background-color: white;
      border-radius: 100%;
      border: 0.125em solid transparent;
      background-clip: padding-box;
      z-index: 2;
      transform-origin: right center;
    }

    &::after {
      position: absolute;
      left: 0.675em;
      top: 0;
      line-height: 2;
      letter-spacing: 0.5em;
      z-index: 1;
    }

    &:focus {
      color: white;
      border-color: transparent;
      background-color: #ddd;
      outline: none;
    }
  }

  input[type='checkbox']:checked {
    color: white;
    background-color: rgb(40, 167, 69);
    border-color: transparent;

    &::before {
      transform-origin: left center;
    }
  }

  input[type='checkbox']::before {
    animation: switch-off-position 0.25s ease-out forwards;
  }

  input[type='checkbox']:checked::before {
    animation: switch-on-position 0.25s ease-out forwards;
  }

  input[type='checkbox']::before {
    animation: switch-off-transform 0.25s ease-out forwards;
  }

  input[type='checkbox']:checked::before {
    animation: switch-on-transform 0.25s ease-out forwards;
  }
}

.ship_form_armings_category {
  .form-check {
    input[type='checkbox'] {
      width: 2em;
      vertical-align: text-bottom;
      margin-right: 10px;
      height: 1em;
      background-color: #ccc;

      &:checked {
        background-color: rgb(40, 167, 69);
      }
    }
  }
}

#station_form_longitude,
#ship_form_gps_coordinate_longitude {
  .form-check {
    input[type='checkbox'] {
      &:after {
        content: 'O E';
      }
    }
  }
}

#station_form_latitude,
#ship_form_gps_coordinate_latitude {
  .form-check {
    input[type='checkbox'] {
      &:after {
        content: 'N S';
      }
    }
  }
}

@keyframes switch-on-position {
  0% {
    left: 0;
    right: 50%;
  }

  25% {
    left: 0;
    right: 37.5%;
  }

  100% {
    left: 50%;
    right: 0;
  }
}

@keyframes switch-off-position {
  0% {
    left: 50%;
    right: 0;
  }

  25% {
    left: 37.5%;
    right: 0;
  }

  100% {
    left: 0%;
    right: 50%;
  }
}

@keyframes switch-on-transform {
  0% {
    transform: translate(0) scaleX(1);
  }

  25% {
    transform: translate(0) scaleX(1.33);
  }

  100% {
    transform: translate(100%) scaleX(1);
  }
}

@keyframes switch-off-transform {
  0% {
    transform: translate(100%) scaleX(1);
  }

  25% {
    transform: translate(100%) scaleX(1.33);
  }

  100% {
    transform: translate(0) scaleX(1);
  }
}

.capitalize {
  text-transform: uppercase;
}

.form-group .form-group {
  margin-left: 30px;
}

.postfix-form {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: attr(postfix);
    padding: 10px;
    background-color: #f8f8f8;
    border-top: 1px solid #e6e8e8;
    border-right: 1px solid #e6e8e8;
    border-bottom: 1px solid #e6e8e8;
    color: #8b91a0;
    box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.11);
  }
}

@media screen and (min-width: 992px) {
  #ship_form_technical_characteristics {
    .form-group {
      float: left;
      width: 50%;
      margin: 0;
      padding: 5px 15px;
    }
  }

  #ship_form_gps_coordinate,
  #ship_form_hull_informations {
    > .form-group {
      float: left;
      width: 50%;
      margin: 0;
      padding: 5px 15px;
    }
  }
}

@media screen and (max-width: 992px) {
  #ship_form_technical_characteristics {
    .form-group {
      width: 100%;
      float: left;
      margin: 0;
      padding: 5px 15px;
    }
  }

  #ship_form_gps_coordinate,
  #ship_form_hull_informations {
    > .form-group {
      float: left;
      width: 100%;
      margin: 0;
      padding: 5px 15px;
    }
  }
}

.dataTables_length {
  .multiselect-native-select {
    .btn-group {
      display: none;
    }
  }
}

.multiselect-container > li.active > a > label {
  color: #fff;
}

.btn-suppr-file,
.btn-suppr-outSider {
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}

table {
  td {
    position: relative;
    overflow: hidden;
  }

  .break-word {
    display: flex;
    flex-wrap: wrap;
    span {
      margin: 2px;
    }
  }
}

.message-forgot {
  position: absolute;
  height: 0;
  top: 240px;
  left: 0;
  z-index: -1;
  padding: 10px 5px;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;

  &.active {
    height: 35px;
    top: 260px;
  }

  &.error {
    background-color: #ef4215;
  }

  &.success {
    background-color: #5cb85c;
  }

  p {
    margin: 0;
  }
}

.panel-alert {
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px;
  }
}

.fa-map-marker,
.fa-list {
  font-size: 20px;
}