/* ---------------------------------------------------------------------- */

/*  Theme Default
/* ---------------------------------------------------------------------- */

a {
  color: #f1552d;
}

.panel-white {
  color: #333333;
}

.available-panel {
  color: #ffffff;
  float: none;
  margin: auto;
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: 20px;
}

.available-panel button {
  border: none;
  color: #ffffff;
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #f1552d !important;
}

.form-control.input-xs,
.select2-container.small,
.select2-container.small .select2-choice {
  height: 22px !important;
  max-height: 22px !important;
  font-size: 10px !important;
}

.select2-container.small .select2-choice {
  line-height: 14px;
}

body {
  background-color: #f7f7f7;
}

body.layout-boxed {
  background: url("../../images/bg_2.png") #f7f7f7;
}

body.login {
  background-image: url("../../images/test.jpg");
  background-color: #333333;
}

.main-container,
#pageslide-left,
#pageslide-right {
  background-color: #f7f7f7 !important;
}

.label-default,
.badge-default {
  background: #f1552d !important;
}

.topbar {
  background: #333333;
  background-color: #4d4d4d 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4d4d4d 0px), to(#333333 100%));
  background-image: -webkit-linear-gradient(top, #4d4d4d 0px, #333333 100%);
  background-image: -moz-linear-gradient(top, #4d4d4d 0px, #333333 100%);
  background-image: -ms-linear-gradient(top, #4d4d4d 0px, #333333 100%);
  background-image: -o-linear-gradient(top, #4d4d4d 0px, #333333 100%);
  background-image: linear-gradient(top, #4d4d4d 0px, #333333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#4d4d4d 0px', endColorStr='#333333 100%');
}

.topbar > .container .navbar-brand,
.topbar .logo {
  color: #ffffff;
}

@media (max-width: 991px) {
  .topbar > .container .navbar-brand {
    left: 10px !important;
  }

  .topbar > .container .navbar-brand img {
    margin-top: 10px;
  }
}

.topbar .topbar-tools > ul > li > a {
  color: #ffffff;
}

.topbar .topbar-tools > ul > li.right-menu-toggle > a {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #333333;
}

.topbar .nav li.dropdown.open > .dropdown-toggle,
.topbar .nav li.dropdown.active > .dropdown-toggle,
.topbar .nav li.dropdown.open.active > .dropdown-toggle {
  background: none;
  color: #ffffff;
}

.topbar .nav > li:hover > a,
.topbar .nav > li:active > a,
.topbar .nav > li > a:focus {
  color: #ffffff;
  background: none;
}

ul.main-navigation-menu > li a {
  border-bottom: none;
  border-top-color: #eaeaea;
  color: #1e1e1e;
}

ul.main-navigation-menu > li a i {
  color: #b7b7b7;
  font-weight: normal;
}

ul.main-navigation-menu > li.active > a {
  background: #e8e8e8 !important;
  border-top: none !important;
  color: #2c2f3b;
}

ul.main-navigation-menu > li.active > a i {
  color: #2c2f3b;
}

ul.main-navigation-menu li > ul.sub-menu > li > a:hover {
  background: #e8e8e8;
}

ul.main-navigation-menu li > ul.sub-menu > li.open > a,
ul.main-navigation-menu li > ul.sub-menu > li.active > a,
ul.main-navigation-menu li > ul.sub-menu > li.open > a:hover,
ul.main-navigation-menu li > ul.sub-menu > li.active > a:hover {
  background: #f2f2f2;
}

ul.main-navigation-menu > li.open > a,
ul.main-navigation-menu > li > a:hover,
ul.main-navigation-menu > li:hover > a {
  background-color: #f2f2f2;
}

ul.main-navigation-menu li.divider {
  background-color: #eaeaea !important;
}

ul.main-navigation-menu > li.open > a,
ul.main-navigation-menu > li > a:hover,
ul.main-navigation-menu > li:hover > a {
  background-color: #f2f2f2;
}

ul.main-navigation-menu li.dropdown-header,
ul.main-navigation-menu .mega-menu-sub-title {
  color: #ababab !important;
}

ul.main-navigation-menu li.dropdown-header,
ul.main-navigation-menu .mega-menu-sub-title {
  color: #ababab !important;
}

ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu a {
  color: #1e1e1e !important;
}

ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu a:hover {
  background: none !important;
}

ul.main-navigation-menu
.mega-menu
.mega-menu-content
ul.mega-sub-menu
li.active
a,
ul.main-navigation-menu
.mega-menu
.mega-menu-content
ul.mega-sub-menu
li.active
a:hover,
ul.main-navigation-menu
.mega-menu
.mega-menu-content
ul.mega-sub-menu
li.active
a:focus {
  background-color: #e8e8e8 !important;
  text-decoration: none;
}

ul.main-navigation-menu
.mega-menu
.mega-menu-content
ul.mega-sub-menu
li
a:hover {
  background-color: #e8e8e8 !important;
  text-decoration: none;
}

#horizontal-menu .container {
  background: #f7f7f7;
}

#horizontal-menu .navbar-collapse {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

#horizontal-menu .navbar-collapse .navbar-nav {
  margin: 0px !important;
}

#horizontal-menu .navbar-collapse .navbar-nav > li {
  float: left !important;
}

#horizontal-menu .navbar-collapse .navbar-nav > li > a {
  color: #848484;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

#horizontal-menu .navbar-collapse .navbar-nav > li > a:hover {
  color: #1e1e1e;
  background: #eaeaea;
}

#horizontal-menu .navbar-collapse .navbar-nav > li > a:active,
#horizontal-menu .navbar-collapse .navbar-nav > li > a:focus {
  background: #dedede;
}

#horizontal-menu .navbar-collapse .navbar-nav > li > a i {
  display: block;
  line-height: 0px;
  padding-top: 18px;
}

#horizontal-menu .navbar-collapse .navbar-nav > li.active > a {
  color: white;
  background: #f1552d;
}

#horizontal-menu .navbar-collapse .navbar-nav > li.active > a:after {
  border-top-color: #f1552d;
}

.main-content {
  background: #dddddd;
}

.main-content .panel-heading .pull-right {
  position: absolute;
  right: 10px;
  top: 14px;
}

.go-top {
  color: #848484;
}

.footer-inner {
  color: #848484;
}

#pageslide-left .navigation-toggler .sb-toggle-left {
  background: url(../../images/dark_dots.png) no-repeat center center;
}

#pageslide-left .slide-tools {
  background-color: #eaeaea;
}

#pageslide-left .btn {
  color: rgba(44, 47, 59, 0.3);
}

#pageslide-left .btn:hover {
  color: #2c2f3b;
  background-color: rgba(216, 216, 216, 0.4);
}

#pageslide-left .user-profile {
  border-color: #eaeaea;
  position: relative;
}

#pageslide-left .user-profile h5 {
  color: #ababab;
}

#pageslide-left .user-profile h4 {
  color: #2c2f3b;
}

.closedbar {
  background: #eaeaea url(../../images/dark_dots.png) no-repeat center 5px !important;
}

.closedbar:hover {
  background-color: #f7f7f7 !important;
}

#pageslide-right .pageslide-title {
  color: rgba(44, 47, 59, 0.4);
}

#pageslide-right .notifications a {
  color: rgba(44, 47, 59, 0.4);
  background: #efefef;
}

#pageslide-right .notifications a:hover {
  background: #eaeaea;
}

#pageslide-right .notifications a .time {
  color: #ababab;
}

#pageslide-right .right-wrapper .nav-tabs li a {
  background: #f7f7f7;
  border-bottom-color: #dedede;
}

#pageslide-right .right-wrapper .nav-tabs li.active a {
  border-right-color: #dedede;
  border-left-color: #dedede;
  color: rgba(44, 47, 59, 0.9);
}

#pageslide-right .media-list .media {
  border-bottom: 1px solid #dedede;
  margin: 0;
}

#pageslide-right .media-list .media .media-heading {
  color: #374c57 !important;
}

#pageslide-right .media-list .media a:hover {
  background: #dedede;
}

#pageslide-right .media-list .media .status-online {
  color: #1fbba6;
}

#pageslide-right .media-list .media .media-body span {
  color: #454545;
}

#pageslide-right .media-list .media label {
  color: #5e5e5e;
}

#pageslide-right .sidebar-title {
  color: rgba(44, 47, 59, 0.4);
}

#pageslide-right .sidebar-back {
  color: rgba(44, 47, 59, 0.4);
}

#pageslide-right .sidebar-back:hover {
  color: rgba(44, 47, 59, 0.6);
}

#style_selector_container .box-title {
  color: rgba(44, 47, 59, 0.8) !important;
}

#style_selector_container .images {
  border-bottom: 1px solid #eaeaea;
}

#style_selector_container .images img.active {
  border: 2px solid #515151;
}

footer {
  background-color: #f7f7f7;
}

legend {
  text-decoration: none;
  /* font-weight: bold; */
  font-size: 11px;
  color: #858585;
  border-bottom: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #333333;
}

#station_form_longitude,
#station_form_latitude,
#ship_form_gps_coordinate_longitude,
#ship_form_gps_coordinate_latitude {
  display: flex;
  justify-content: flex-start;
}

#station_form_longitude .form-group,
#station_form_latitude .form-group,
#ship_form_gps_coordinate_longitude .form-group,
#ship_form_gps_coordinate_latitude .form-group {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

#station_form_longitude .form-group label,
#station_form_latitude .form-group label,
#ship_form_gps_coordinate_longitude .form-group label,
#ship_form_gps_coordinate_latitude .form-group label {
  width: 20%;
  margin: 0 10px;
}

.form-check input[type='checkbox'] {
  position: relative;
  display: inline-block;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  height: 2em;
  width: 4em;
  font-size: 1em;
  border-radius: 1.5em;
  background-color: #f44242;
  border-color: transparent;
  background-clip: padding-box;
  color: #ddd;
  vertical-align: middle;
  transition: all 0.25s linear 0.25s;
}

.form-check input[type='checkbox'].grey-button {
  background-color: #ccc;
}

.form-check input[type='checkbox'].grey-button:checked {
  background-color: #ccc;
}

.form-check input[type='checkbox']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
  background-color: white;
  border-radius: 100%;
  border: 0.125em solid transparent;
  background-clip: padding-box;
  z-index: 2;
  transform-origin: right center;
}

.form-check input[type='checkbox']::after {
  position: absolute;
  left: 0.675em;
  top: 0;
  line-height: 2;
  letter-spacing: 0.5em;
  z-index: 1;
}

.form-check input[type='checkbox']:focus {
  color: white;
  border-color: transparent;
  background-color: #ddd;
  outline: none;
}

.form-check input[type='checkbox']:checked {
  color: white;
  background-color: #28a745;
  border-color: transparent;
}

.form-check input[type='checkbox']:checked::before {
  transform-origin: left center;
}

.form-check input[type='checkbox']::before {
  animation: switch-off-position 0.25s ease-out forwards;
}

.form-check input[type='checkbox']:checked::before {
  animation: switch-on-position 0.25s ease-out forwards;
}

.form-check input[type='checkbox']::before {
  animation: switch-off-transform 0.25s ease-out forwards;
}

.form-check input[type='checkbox']:checked::before {
  animation: switch-on-transform 0.25s ease-out forwards;
}

.ship_form_armings_category .form-check input[type='checkbox'] {
  width: 2em;
  vertical-align: text-bottom;
  margin-right: 10px;
  height: 1em;
  background-color: #ccc;
}

.ship_form_armings_category .form-check input[type='checkbox']:checked {
  background-color: #28a745;
}

#station_form_longitude .form-check input[type='checkbox']:after,
#ship_form_gps_coordinate_longitude .form-check input[type='checkbox']:after {
  content: 'O E';
}

#station_form_latitude .form-check input[type='checkbox']:after,
#ship_form_gps_coordinate_latitude .form-check input[type='checkbox']:after {
  content: 'N S';
}

@keyframes switch-on-position {
  0% {
    left: 0;
    right: 50%;
  }

  25% {
    left: 0;
    right: 37.5%;
  }

  100% {
    left: 50%;
    right: 0;
  }
}

@keyframes switch-off-position {
  0% {
    left: 50%;
    right: 0;
  }

  25% {
    left: 37.5%;
    right: 0;
  }

  100% {
    left: 0%;
    right: 50%;
  }
}

@keyframes switch-on-transform {
  0% {
    transform: translate(0) scaleX(1);
  }

  25% {
    transform: translate(0) scaleX(1.33);
  }

  100% {
    transform: translate(100%) scaleX(1);
  }
}

@keyframes switch-off-transform {
  0% {
    transform: translate(100%) scaleX(1);
  }

  25% {
    transform: translate(100%) scaleX(1.33);
  }

  100% {
    transform: translate(0) scaleX(1);
  }
}

.capitalize {
  text-transform: uppercase;
}

.form-group .form-group {
  margin-left: 30px;
}

.postfix-form {
  position: relative;
  overflow: hidden;
}

.postfix-form::after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: attr(postfix);
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e6e8e8;
  border-right: 1px solid #e6e8e8;
  border-bottom: 1px solid #e6e8e8;
  color: #8b91a0;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.11);
}

@media screen and (min-width: 992px) {
  #ship_form_technical_characteristics .form-group {
    float: left;
    width: 50%;
    margin: 0;
    padding: 5px 15px;
  }

  #ship_form_gps_coordinate > .form-group,
  #ship_form_hull_informations > .form-group {
    float: left;
    width: 50%;
    margin: 0;
    padding: 5px 15px;
  }
}

@media screen and (max-width: 992px) {
  #ship_form_technical_characteristics .form-group {
    width: 100%;
    float: left;
    margin: 0;
    padding: 5px 15px;
  }

  #ship_form_gps_coordinate > .form-group,
  #ship_form_hull_informations > .form-group {
    float: left;
    width: 100%;
    margin: 0;
    padding: 5px 15px;
  }
}

.dataTables_length .multiselect-native-select .btn-group {
  display: none;
}

.multiselect-container > li.active > a > label {
  color: #fff;
}

.btn-suppr-file,
.btn-suppr-outSider {
  position: absolute;
  top: 0;
  right: 0;
}

.btn-suppr-file:hover,
.btn-suppr-outSider:hover {
  cursor: pointer;
}

table td {
  position: relative;
  overflow: hidden;
}

table .break-word {
  display: flex;
  flex-wrap: wrap;
}

table .break-word span {
  margin: 2px;
}

.message-forgot {
  position: absolute;
  height: 0;
  top: 240px;
  left: 0;
  z-index: -1;
  padding: 10px 5px;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.message-forgot.active {
  height: 35px;
  top: 260px;
}

.message-forgot.error {
  background-color: #ef4215;
}

.message-forgot.success {
  background-color: #5cb85c;
}

.message-forgot p {
  margin: 0;
}

.panel-alert {
  display: flex;
  justify-content: center;
}

.panel-alert button {
  margin: 0 5px;
}

.fa-map-marker,
.fa-list {
  font-size: 20px;
}

